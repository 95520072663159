<template>
  <div id="app">
    <h1>LABÜROSO</h1>
    <h2>Labüroso bedeutet so zu arbeiten, <br/> wie es uns gefällt.
      <br/>Gemeinsam forschen, experimentieren und realisieren wir
      zwischen Kommunkationsdesign und Medienkunst.<br/>
      Im Labor und im Büro, oder sonst wo. </h2>
    <a href="mailto:hi@labüroso.ch"><h3>hi@labüroso.ch</h3></a>
    <a href="https://www.instagram.com/laburoso/" target="blank"><h3>&rarr; Instagram</h3></a>
    <a href="https://github.com/laburoso" target="blanks"><h3>&rarr; Github</h3></a>
  </div>
</template>

<script>
export default {
  name: 'Labüroso',
};
</script>

<style lang="scss">
@font-face {
  font-family: 'bandeins-light';
  src: url('assets/BandeinsStrange-Light.otf') format("opentype");
}

@font-face {
  font-family: 'bandeins-ext';
  src: url('assets/BandeinsStrange-MediumExtendedHalf.otf') format("opentype");
}

html {
  background-color: black;
  color: white;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 12vw;
  line-height: 1.2;
  font-family: 'bandeins-ext';
}

h2 {
  font-size: 7.9vw;
  line-height: 1.5;
  font-family: 'bandeins-light';
  margin-bottom: 5vh;
}

h3 {
  font-size: 7.9vw;
  line-height: 0.5;
  font-family: 'bandeins-ext';
}

a {
  text-decoration: none;
  color: white;
}

#app {
  padding-left: 2vw;
  padding-top: 2vw;
  height: 95vh;
  width: 85vw;
  padding-bottom: 5vh;
}
</style>
